<template>
  <b-embed type="iframe" :src="url"></b-embed>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import config from "@/core/config/layout.config.json";
import JwtService from "@/core/services/jwt.service";

export default {
  data() {
    return {
      url: ""
    };
  },

  mounted() {
    if (this.isAdmin) {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "LOGS" }]);
      this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");

      let newConfig = JSON.parse(JSON.stringify(config));
      newConfig.header.self.display = false;
      newConfig.subheader.display = false;
      newConfig.styleContainer = { "max-width": "100%", margin: 0 };
      newConfig.scrollTop.self.display = false;
      newConfig.aside.self.minimize.hoverable = true;
      newConfig.wrapper = { "padding-top": "20px" };
      this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);

      const api = process.env.VUE_APP_COMUNICANTE_API_PAINEL;
      const token = JwtService.getToken();

      this.url = `${api}/logs?token=${token}`;
    } else {
      this.$router.push({
        name: "dashboard"
      });
    }
  },

  beforeDestroy() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
    this.$store.dispatch(SET_LAYOUT_CONFIG, JSON.parse(JSON.stringify(config)));
  },

  computed: {
    ...mapGetters(["isAdmin"])
  }
};
</script>
